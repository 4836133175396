<template>
  <div class="Page">


  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created(opt) {},
  methods: {},
};
</script>

<style scoped lang="scss">
.Page {
  margin-top: 0px;


}
</style>
